import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import { Heading, Text, Button, ScrollArrow, Image } from '_atoms'
import { SEO, AboutUs } from '_organisms'
import {
  Hero,
  SideTitleText,
  SuccessStories,
  Engagements,
  Awards,
  Services,
  FooterCompact,
} from '_molecules'
import { NavbarOnly, QuoteSection } from '_templates'
import ContactPage from '_pages/contact'
import { scrollTo } from '_utils/scroll'
import { camelCase } from '_utils/config'

import { DISPLAY_ORIENTATIONS } from '../../../components/molecules/services'
import SEO_VARIABLES from '../../../config/seo-variables'

import styles from './styles.module.css'

const MobileAppDevelopmentPage = ({ data }) => {
  const content = data.allContentfulLandingPages.edges[0].node

  // Needed for Google Tag Manager click count on CTA buttons
  const gtmPageName = camelCase(content.name)

  const cta = {
    text: content.heroButtonText,
    scrollTo: 'contact',
    id: `${gtmPageName}NavigationButton`,
  }

  const contactData = {
    contentfulPageMetatags: {
      contactTitle: content.pageTitle,
      contactDescription: content.pageDescription,
      contactThumbnail: content.pageThumbnail,
    },
    hideFooter: true,
  }

  const customHeroContent = (
    <Row>
      <Col xs={12} sm={7} md={7} lg={7}>
        <Fade distance="20%" top>
          <Heading type="h4" bold className={styles.heroTitle}>
            {content.heroTitle}
            <span className={styles.squared}>.</span>
          </Heading>
        </Fade>

        <Fade distance="20%" delay={200} bottom>
          <Text size="28" className={styles.heroDescription}>
            {content.heroDescription.internal.content}
          </Text>
        </Fade>
        <Fade distance="20%" delay={200} bottom>
          <Button
            type="outlineWhite"
            className={styles.heroButton}
            onClick={() => scrollTo('contact')}
            id={`${gtmPageName}HeroButton`}
          >
            {content.heroButtonText}
          </Button>
        </Fade>

        <Fade distance="20%" bottom>
          <button
            type="button"
            className={styles.heroArrowButton}
            onClick={() => scrollTo('sideTitleText')}
          >
            <ScrollArrow className={styles.heroArrow} />
          </button>
        </Fade>
      </Col>
      <Col xs={12} sm={5} md={5} lg={5} className={styles.heroImageWrapper}>
        <Fade distance="20%" delay={350} bottom>
          <Image src={content.heroImage.file.url} className={styles.image} />
        </Fade>
      </Col>
    </Row>
  )

  return (
    <NavbarOnly cta={cta}>
      <SEO
        title={content.pageTitle || SEO_VARIABLES.title}
        description={content.pageDescription || SEO_VARIABLES.description}
        thumbnail={content.pageThumbnail.file.url}
      />

      <Hero
        className={styles.hero}
        contentClassName={styles.heroContent}
        customContent={customHeroContent}
      />
      <Services
        title={content.servicesTitle}
        description={content.servicesDescription}
        services={content.services}
        displayOrientation={DISPLAY_ORIENTATIONS.row}
      />
      <div className={styles.sideTextWrapper}>
        <SideTitleText
          title={content.secondSectionTitle}
          description={content.secondSectionDescription}
          buttonText={content.secondSectionButtonText}
          onClick={() => scrollTo('contact')}
          id={`${gtmPageName}HowWeCanHelpButton`}
        />
      </div>
      <QuoteSection
        quote={content.quote}
        buttonText={content.quoteButtonText}
        onClick={() => scrollTo('contact')}
        id={`${gtmPageName}QuoteButton`}
      />
      <SuccessStories title={content.successStoriesTitle} successStories={content.successStories} />

      {content.engagements && (
        <Engagements title={content.engagementsTitle} engagements={content.engagements} />
      )}

      <AboutUs
        title={content.aboutUsTitle}
        description={content.aboutUsDescription}
        image={content.aboutUsImage}
        buttonText={content.aboutUsButtonText}
        buttonDescription={content.aboutUsButtonTextDescription}
        onClick={() => scrollTo('contact')}
        id={`${gtmPageName}AboutUsButton`}
      />

      <Awards title={content.awardsTitle} awards={content.awards} />

      <section id="contact">
        <ContactPage data={contactData} />
      </section>

      <FooterCompact />
    </NavbarOnly>
  )
}

export const query = graphql`
  query DedicatedTeamsPageContent4($locale: String) {
    allContentfulLandingPages(
      filter: { name: { eq: "Mobile App Development" }, fields: { localeKey: { eq: $locale } } }
    ) {
      edges {
        node {
          name
          pageTitle
          pageDescription
          pageThumbnail {
            file {
              url
            }
          }
          heroTitle
          heroDescription {
            internal {
              content
            }
          }
          heroButtonText
          heroImage {
            description
            fixed(quality: 100) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
            file {
              url
            }
          }
          secondSectionTitle
          secondSectionDescription {
            internal {
              content
            }
          }
          secondSectionButtonText
          successStoriesTitle
          successStories {
            image {
              description
              file {
                url
              }
            }
            description {
              internal {
                content
              }
            }
          }
          quote {
            text {
              internal {
                content
              }
            }
            user
            jobDescription
            userImage {
              fixed(width: 64, height: 64, quality: 80) {
                src
                srcSet
              }
            }
          }
          quoteButtonText
          servicesTitle
          servicesDescription {
            internal {
              content
            }
          }
          services {
            image {
              description
              file {
                url
              }
            }
            title
          }
          engagementsTitle
          engagements {
            ... on ContentfulEngagements {
              image {
                description
                file {
                  url
                }
              }
              title
              description {
                internal {
                  content
                }
              }
            }
          }
          aboutUsTitle
          aboutUsDescription {
            internal {
              content
            }
          }
          aboutUsImage {
            description
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          aboutUsButtonText
          aboutUsButtonTextDescription
          awardsTitle
          awardsDescription {
            ... on ContentfulLandingPages {
              internal {
                content
              }
            }
          }
          awards {
            image {
              description
              fixed(width: 96, height: 96, quality: 100) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
            title
            description
          }
        }
      }
    }
  }
`

MobileAppDevelopmentPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default MobileAppDevelopmentPage
